/**
 * Theme global.js
 */

function init() {
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {
        if ($('.main-menu .pll-parent-menu-item > a').length) {
            $('.main-menu .pll-parent-menu-item > a').append('<i class="fa fa-angle-down fa-lg"></i>');
        }

        $('.tujaus.btn').on('click', function (e) {
            e.preventDefault();
            $('.calendar-overlay').css({
                'height': '100%',
                'width': '100%'
            });
            $('#tujausDiv').detach().prependTo('.calendar-wrapper');
        });

        $('#close-calendar').on('click', function (e) {
            $('#tujausDiv').detach().prependTo('.tujaus-sidebar-wrapper');
            $('.calendar-overlay').css({
                'height': '0',
                'width': '0'
            });
        })


    });
})(jQuery);
